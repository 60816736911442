import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/configure',
        name: 'configure',
        component: () => import(/* webpackChunkName: "configure" */ '../views/Configure.vue'),
        meta: {
            landscape: true
        }
    },
    {
        path: '/play',
        name: 'play',
        component: () => import(/* webpackChunkName: "play" */ '../views/Play.vue'),
        meta: {
            landscape: true
        }
    },
    {
        path: '/end/:termination',
        name: 'end',
        component: () => import(/* webpackChunkName: "end" */ '../views/End.vue'),
        meta: {
            landscape: true
        }
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
