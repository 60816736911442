import Vue from 'vue'
import Vuex from 'vuex'
import {GameStoreState, PlayerConfiguration} from "@/types";
import axios from "axios";
import moment from "moment-timezone";

Vue.use(Vuex)

const SYNCHRO_DATA_COUNT = 10;

export default new Vuex.Store<GameStoreState>({
    state: {
        configuration: {},
        orientation: "",
        configured: false,
        currentSessionId: localStorage.getItem("session_id"),
        gameState: null,
        playerState: null,
        clientConfiguration: {},
        synchro: {
            data: [],
            index: 0,
            offset: 0,
            client: "",
            server: ""
        },
    },
    mutations: {
        setConfiguration(state: GameStoreState, configuration: PlayerConfiguration) {
            state.configuration = configuration;
            state.configured = true;
        },
        setCurrentSessionId(state: GameStoreState, sessionId: string|null) {
            state.currentSessionId = sessionId;
            if(sessionId) {
                localStorage.setItem("session_id", sessionId);
            }
            else {
                localStorage.removeItem("session_id");
            }
        },
        setOrientation(state: GameStoreState) {
            const o = window.orientation; // compatible mobile plus ou moins uniquement

            state.orientation = (o === 90 || o === -90) ? 'landscape' : 'portrait';
        },
        setGameState(state: GameStoreState, gameState: any) {
            state.gameState = gameState;
        },
        setPlayerState(state: GameStoreState, playerState: any) {
            state.playerState = playerState;
        },
        setClientConfiguration(state: GameStoreState, conf: any) {
            state.clientConfiguration = conf;
        },
        putSynchro(state: GameStoreState, data: any) {
            state.synchro.data[state.synchro.index] = data.client - data.server;
            state.synchro.index = (state.synchro.index + 1) % SYNCHRO_DATA_COUNT;
            let total = 0;
            const n = state.synchro.data.length;
            for(let i = 0; i < n; ++i) {
               total += state.synchro.data[i];
            }
            state.synchro.offset = total / n;
            state.synchro.client = moment(data.client).format("HH:mm:ss.SSS");
            state.synchro.server = moment(data.server).format("HH:mm:ss.SSS");
        }
    },
    getters: {
        isPortrait(state: GameStoreState) {
            return state.orientation === 'portrait';
        },
        isLandscape(state: GameStoreState) {
            return state.orientation === 'landscape';
        },
        hasConfigurationPhase(state) {
            return state.clientConfiguration && state.clientConfiguration.clients && (state.clientConfiguration.clients.HAS_CONFIGURATION === true);
        },
        isConfigured(state, getters) {
            if(getters.hasConfigurationPhase) {
                return state.configured;
            }
            else {
                return true;
            }
        },
        isLandscapeModeForced(state) {
            return (state.clientConfiguration === null) || (state.clientConfiguration === undefined)
                || (state.clientConfiguration.clients === null) || (state.clientConfiguration.clients === undefined)
                || (state.clientConfiguration.clients.FORCE_LANDSCAPE !== false);
        }
    },
    actions: {
        async loadClientConfiguration({commit}): Promise<any> {
            const result = await axios.get(process.env.VUE_APP_API_URL + "/configuration/clients")

            commit("setClientConfiguration", result.data);
            return result.data;
        }
    },
    modules: {
    }
})
