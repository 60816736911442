
import {Component, Vue} from 'vue-property-decorator';
import Action from "@/components/Action.vue";
import {Getter} from "vuex-class";

@Component({
    name: "Home",
    components: {Action},
})
export default class Home extends Vue {
    // props
    @Getter("hasConfigurationPhase") hasConfigurationPhase!: boolean;

    // data

    // computed
    get nextScreen(): any {
        if(this.hasConfigurationPhase) {
            return {name: "configure"};
        }
        else {
            return {name: "play"};
        }
    }
    // methods

    // watch

    // hooks
}
