import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue2TouchEvents from "vue2-touch-events";
import VBodyScrollLock from "v-body-scroll-lock";
import VueGtag from "vue-gtag";

Vue.use(Vue2TouchEvents)
Vue.use(VBodyScrollLock)


console.log("Configure GTAG with ID: " + process.env.VUE_APP_GOOGLE_ANALYTICS);
Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS}
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
