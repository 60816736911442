
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import OrientationAlert from "@/components/OrientationAlert.vue";

@Component({
    components: {OrientationAlert}
})
export default class App extends Vue {
    @Getter('isPortrait') isPortrait!: boolean;
    @Getter("isLandscapeModeForced") isLandscapeModeForced!: boolean;
    @Mutation('setOrientation') setOrientation!: () => void;
    @Action("loadClientConfiguration") loadClientConfiguration!: () => Promise<any>;

    get displayOrientationAlert() {
        // return this.isPortrait && this.$route.meta?.landscape && this.isLandscapeModeForced;
        return false;
    }

    async beforeMount() {
        this.setOrientation()
        window.onorientationchange = this.setOrientation;

        await this.loadClientConfiguration();
    }
}
